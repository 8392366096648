import { ApolloClient } from "@apollo/client";
import { PreGeneratedIdentityKey, SealdSDK } from "@seald-io/sdk/lib/main";
import {
  encryptionContext as encryptionContextFragment,
  requestActions,
} from "core/apollo/graphql/fragments/fragments";
import { REQUEST_ACTION_ACCESS_TO_DATA } from "core/consts";
import { ENV_DEVELOPMENT, ENV_STAGING } from "core/model/config";
import {
  ApolloCacheData,
  AuctionRequest,
  EncryptionContext,
  Env,
  EnvContext,
  PendingSealdAccess,
  RequestActionTypes,
} from "core/types";
import gql from "graphql-tag";
import { capitalize } from "lodash";
import { EntityType } from "./authFlows";

const preGeneratedKeys: Array<PreGeneratedIdentityKey> = [];

export const isDev = (env: Env) =>
  env === ENV_DEVELOPMENT || env === ENV_STAGING;

export const getNonce = (userLicenseToken: string) =>
  userLicenseToken.split(":")[1];

export function isWeb() {
  if (typeof document !== "undefined") {
    if (window?.localStorage) {
      return true;
    }
  }
  return false;
}

export function preGenerateKeys(sealdSDKInstance: SealdSDK, nKeys: number) {
  sealdSDKInstance.preGenerateIdentityKeys(nKeys);

  for (let i = 0; i < nKeys; i++) {
    const key = sealdSDKInstance.pullPreGeneratedIdentityKeyFromPool();
    if (key) preGeneratedKeys.push(key);
  }
}

export function pushAllPreGenerateKeys(sealdSDKInstance: SealdSDK) {
  for (let i = 0; i < preGeneratedKeys.length; i++) {
    const key = preGeneratedKeys.pop();
    if (key) sealdSDKInstance.pushPreGeneratedIdentityKeyToPool(key);
  }
}

export function computeSSKSEmail({
  email,
  envContext,
  nonce,
}: {
  email: string;
  envContext: EnvContext;
  nonce: string;
}) {
  if (isDev(envContext.env)) {
    return nonce + email;
  }
  return email;
}

export function computeSealdDisplayId({
  envContext,
  id,
  type,
}: {
  envContext: EnvContext;
  id: number;
  type: EntityType;
}) {
  const displayId = `${type}-${id}`;
  if (isDev(envContext.env) && process.env.PR_NAME)
    return `${displayId}-pr-${process.env.PR_NAME}`;
  return displayId;
}

export function computeIdFromSealdDisplayId({
  displayId,
}: {
  displayId: string;
}) {
  const type = displayId.split("-")[0] as EntityType;
  const id = Number(displayId.split("-")[1]);
  return [type, id] as const;
}

export function getSealdEntityFromPayload(
  payload: EncryptionContext | PendingSealdAccess,
) {
  const entity = Object.entries(payload)
    .filter(
      ([key, value]) =>
        key.includes("_id") && !key.includes("seald") && value != null,
    )
    .map(([key, value]) => [key.replace("_id", ""), value])
    .pop();
  if (!entity) throw new Error("Malformed PSK");
  const [entityType, entityId] = entity;
  return [entityType, entityId] as [EntityType, number];
}

export function getIdsFromSealdDisplayIds(
  entityUsersDisplayIds: Array<string>,
) {
  return entityUsersDisplayIds.map((userDisplayId) => {
    const [userType, id] = computeIdFromSealdDisplayId({
      displayId: userDisplayId,
    });
    return [`${userType}_id`, id] as const;
  });
}

export function updateSealdContextInApolloCache({
  apolloClient,
  encryptionContext,
  entityType,
  id,
}: {
  apolloClient: ApolloClient<ApolloCacheData>;
  encryptionContext: EncryptionContext;
  entityType: string;
  id: number;
}) {
  const normalizedEntity = entityType
    // e.g. auction_request
    .split("_")
    .map(capitalize)
    // AuctionRequest
    .join("");
  apolloClient.writeQuery({
    query: gql`
            query ${normalizedEntity} {
              ${normalizedEntity} {
                id
                has_seald_encryption_context
                seald_encryption_context {
                  ${encryptionContextFragment()}
                }
              }
            }
          `,
    data: {
      [normalizedEntity]: {
        id,
        has_seald_encryption_context: true,
        seald_encryption_context: encryptionContext,
        __typename: normalizedEntity,
      },
    },
  });
}

export function optimisticallySetShareActionInApolloCache({
  apolloClient,
  auctionRequest,
}: {
  apolloClient: ApolloClient<ApolloCacheData>;
  auctionRequest: AuctionRequest;
}) {
  apolloClient.writeQuery({
    query: gql`
            query AuctionRequest {
              AuctionRequest {
                id
                ${requestActions}
              }
            }
          `,
    data: {
      AuctionRequest: {
        id: auctionRequest.id,
        request_actions: auctionRequest.request_actions?.filter(
          ({ action_type }: RequestActionTypes) =>
            action_type !== REQUEST_ACTION_ACCESS_TO_DATA,
        ),
        __typename: "AuctionRequest",
      },
    },
  });
}
