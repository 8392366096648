import { ApolloClient } from "@apollo/client";
import { SealdSDK as SealdSDKType } from "@seald-io/sdk/lib/main";
import {
  useCreateEncryptionContext,
  useInitiateSealdIdentity,
} from "apollo/hooks/mutations";
import {
  ACCOUNT_STATUS_ACTIVE,
  ACCOUNT_TYPE_STAFF,
  SEALD_CONTEXT_TYPE_GROUP,
} from "core/consts";
import { activateSealdGroups } from "core/model/utils/featureFlags";
import {
  Account,
  ApolloCacheData,
  AppType,
  Careprovider,
  Careseeker,
  EnvContext,
  Recipients,
  SealdAccessPayload,
  TrackEventFn,
} from "core/types";
import { Locale as LocaleString } from "translations";
import { getSealdSDKInstance } from ".";
import {
  computeSealdDisplayId,
  getIdsFromSealdDisplayIds,
  updateSealdContextInApolloCache,
} from "./utils";
import { createIdentityForAccount } from "./authFlows";

type GroupType = "careprovider" | "careseeker";

export type CreateGroupInContextProps = {
  createEncryptionContext: ReturnType<typeof useCreateEncryptionContext>[0];
  createSealdAccess:
    | ReturnType<typeof useInitiateSealdIdentity>[0]
    | ((sealdAccess: Partial<SealdAccessPayload>) => Promise<null>);
  entity: Careprovider | Careseeker | undefined;
  entityAccounts: Account[];
  entityType: GroupType;
  initiateSealdIdentity: ReturnType<typeof useInitiateSealdIdentity>[0];
  loggedAccount: Account;
};

export async function safeAddGroupMembers({
  groupId,
  newAdmins,
  newMembers,
  sealdSDKInstance,
}: {
  groupId: string;
  newAdmins?: Recipients;
  newMembers: Recipients;
  sealdSDKInstance: SealdSDKType;
}) {
  async function add() {
    await sealdSDKInstance.addGroupMembers(groupId, newMembers, newAdmins);
  }
  try {
    await add();
  } catch (err) {
    const message = err instanceof Error ? err.message : JSON.stringify(err);
    if (
      message.includes("WRONG_POSITION") ||
      message.includes("POSITION_ALREADY_EXISTS") ||
      message.includes("MEMBERS_MISMATCH")
    ) {
      // happens if parallel PSKs try to add acccounts to the same group, because of the sigchain
      await new Promise((r) => setTimeout(r, 10000));
      await add();
    } else throw err;
  }
}

export async function createSealdGroup({
  apolloClient,
  app,
  createEncryptionContext,
  createSealdAccess,
  entity,
  entityAccounts,
  entityType,
  envContext,
  initiateSealdIdentity,
  localeString,
  loggedAccount,
  trackEvent,
}: CreateGroupInContextProps & {
  apolloClient: ApolloClient<ApolloCacheData>;
  app: AppType;
  envContext: EnvContext;
  localeString: LocaleString;
  loggedAccount: Account | undefined;
  trackEvent: TrackEventFn;
}) {
  if (!activateSealdGroups(app)) return;

  if (!entity || entity?.seald_encryption_context) return;

  const entityId = entity.id;

  if (!loggedAccount) return;

  if (loggedAccount.account_type === ACCOUNT_TYPE_STAFF) {
    console.log("Group creation skipped for staff account");
    return;
  }

  const sealdSDKInstance = await getSealdSDKInstance();

  const sealdGroupUserId = computeSealdDisplayId({
    id: entityId,
    type: entityType,
    envContext,
  });

  const accountUserId = computeSealdDisplayId({
    id: loggedAccount.id,
    type: "account",
    envContext,
  });

  const group = await sealdSDKInstance.createGroup({
    groupName: sealdGroupUserId,
    members: {
      userIds: [accountUserId],
    },
    admins: {
      userIds: [accountUserId],
    },
  });

  if (!group) return;

  const { data } = await createEncryptionContext({
    seald_id: group?.id,
    seald_display_id: group?.groupName,
    seald_type: SEALD_CONTEXT_TYPE_GROUP,
    [`${entityType}_id`]: entityId,
  });
  const encryptionContext = data?.encryptionContext;
  if (!encryptionContext) throw new Error("No encryption context returned");

  // create access for author account
  await createSealdAccess({
    account_id: loggedAccount.id,
    seald_id: group?.id,
  });

  // write to cache AFTER the accesses are created, so the seald context has_access is synced
  // this retriggers the entity query if it is network-only
  updateSealdContextInApolloCache({
    apolloClient,
    encryptionContext,
    entityType,
    id: entityId,
  });

  // create accesses for remaining accounts
  for (const entityAccount of entityAccounts) {
    if (
      entityAccount.status === ACCOUNT_STATUS_ACTIVE &&
      entityAccount.id !== loggedAccount.id
    ) {
      let accountToAdd: Account | undefined;

      if (entityAccount.seald_user_license_token) {
        accountToAdd = entityAccount;
      } else {
        accountToAdd = await createIdentityForAccount({
          account: entityAccount,
          envContext,
          trackEvent,
          initiateSealdIdentity,
          localeString,
        });
      }

      if (!accountToAdd) return;

      const accountToAddUserId = computeSealdDisplayId({
        id: accountToAdd.id,
        type: "account",
        envContext,
      });
      await safeAddGroupMembers({
        groupId: group.id,
        newMembers: { userIds: [accountToAddUserId] },
        newAdmins: { userIds: [accountToAddUserId] },
        sealdSDKInstance,
      });

      // if the new account is in the password flow, it will be reset on the first login
      // on the challenge flow, it will retrieve this pre-created identity
      await createSealdAccess({
        account_id: accountToAdd.id,
        seald_id: group.id,
      });
      console.log(`[${accountToAddUserId}] added to [${group?.groupName}]`);
    }
  }
}

export async function addUsersToGroup({
  accountsDisplayIds,
  createSealdAccess,
  groupId,
}: {
  accountsDisplayIds: Array<string>;
  createSealdAccess: (
    sealdAccess: Partial<SealdAccessPayload>,
  ) => Promise<null>;
  groupId: string;
}) {
  try {
    const sealdSDKInstance = await getSealdSDKInstance();

    await safeAddGroupMembers({
      groupId,
      newMembers: { userIds: accountsDisplayIds },
      newAdmins: { userIds: accountsDisplayIds },
      sealdSDKInstance,
    });

    for (const [idProp, id] of getIdsFromSealdDisplayIds(accountsDisplayIds)) {
      await createSealdAccess({
        [idProp]: id,
        seald_id: groupId,
      });
    }
  } catch (inviteError) {
    console.groupCollapsed("Seald: Failed to invite users");
    console.error(
      `Seald: Failed to invite users ${JSON.stringify(
        accountsDisplayIds,
      )} to group ${groupId}`,
      inviteError,
    );
    console.groupEnd();
  }
}
